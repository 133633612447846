import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchJsonWithDefaultHeaders } from 'common/http';
import { defaultHeaders } from 'common/http/index';
import { Filter } from '../../accessibleBrowseFilters/types';
import { RootState } from './store';
import { createTags, buildFacets } from '../helpers';
import { BrowseConfig } from 'browse3/types';

export interface BrowseConfigState {
  filters: Filter[];
  tags?: Filter;
  config: BrowseConfig;
  browseConfigIsLoaded: boolean;
  filtersAreSet: boolean;
}

const initialState: BrowseConfigState = {
  filters: [],
  config: {},
  browseConfigIsLoaded: false,
  filtersAreSet: false
};

export const fetchBrowseConfig = createAsyncThunk<BrowseConfig, undefined, { state: RootState }>(
  'browseConfig/fetchBrowseConfig',
  async (_, thunkAPI) => {
    const apiPath = '/api/browse_config';
    const options: RequestInit = {
      credentials: 'same-origin',
      headers: defaultHeaders
    };

    const result = await fetchJsonWithDefaultHeaders(apiPath, options);
    return result;
  }
);

export const BrowseConfigSlice = createSlice({
  name: 'browseConfig',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<string[]>) => {
      const { payload } = action;
      if (state.browseConfigIsLoaded) {
        state.filters = buildFacets(state.config, payload);
        state.filtersAreSet = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrowseConfig.fulfilled, (state, action: PayloadAction<BrowseConfig>) => {
        const { payload } = action;
        if (payload) {
          state.config = payload;
          // some domains have zero tags
          if (payload.tags) {
            state.tags = createTags(payload.tags.options);
          }
        }

        state.browseConfigIsLoaded = true;
      })
      .addCase(fetchBrowseConfig.rejected, (state, action) => {
        console.log('BrowseConfig call was not successful'); // EN-68393
      });
  }
});

export const getBrowseConfig = (state: RootState) => {
  return state.browseConfig;
};

export const getBrowseConfigFilters = (state: RootState) => {
  return state.browseConfig.filters;
};

export const getTags = (state: RootState) => {
  return state.browseConfig.tags;
};

export const { setFilters } = BrowseConfigSlice.actions;
